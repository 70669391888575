<template>
  <v-container class="d-flex flex-column">
    <BreadcrumbsField v-if="!showHeader" :items="itemsBreadcrumbs" class="align-self-end"/>
    <v-card>
     <v-form @submit.prevent="saveForm" ref="form">
        <v-card-title v-if="showHeader">
          <span class="headline">{{ $t('topics.formTitle') }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row v-if="!isCacPng">
              <v-col cols="12">
                <v-text-field
                  :label="`${$t('general.externalId')} *`"
                  v-model="external_id"
                  :rules="[isEmptyRule]"
                />
                <v-text-field
                  :label="`${$t('general.description')} *`"
                  v-model="description"
                  :rules="[isEmptyRule]"
                />
                <v-text-field
                  :label="$t('general.color')"
                  v-model="color"
                  :rules="[isColorRule]"
                />
                <v-text-field
                  :label="$t('topics.aggressiveness')"
                  v-model.number="aggressiveness"
                  :rules="[isIntegerRule]"
                />
              </v-col>
              <v-col cols="12">
                <v-checkbox v-model="active" :label="$t('general.active')" />
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12">
                <v-text-field
                  :label="`${$t('general.externalId')} *`"
                  v-model="external_id"
                  :rules="[isEmptyRule]"
                  disabled
                />
                <v-text-field
                  :label="`${$t('general.name')} *`"
                  v-model="name"
                  :rules="[isEmptyRule]"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-form>
    </v-card>
    <div class="d-flex justify-end mt-5">
<!--      <v-btn class="cancel mr-3" color="primary" text @click="closeForm">{{ $t('general.cancel') }}</v-btn>-->
      <v-btn class="save mr-3" color="primary" @click="saveForm">{{ $t('general.save') }}</v-btn>
      <v-btn class="save" color="primary" @click="saveAndContinue">{{ $t('customActions.saveAndContinue') }}</v-btn>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { validationMixin } from '@/apps/core/mixins/validations'
import BreadcrumbsField from '@/apps/core/components/forms/BreadcrumbsField'
import {
  createErrorNotification,
  createWarningNotification,
  createSuccessNotification
} from '@/lib/unnotificationsqueue'
import { IS_CAC, IS_PNG } from '../../../../variables'

export default {
  name: 'TopicForm',
  components: { BreadcrumbsField },
  props: {
    showHeader: Boolean
  },
  mixins: [validationMixin],
  data () {
    return {
      textAlert: '',
      showAlert: false,
      storeModule: 'budgetConcepts'
    }
  },
  computed: {
    ...mapState('budgetConcepts', ['currentItem']),
    itemsBreadcrumbs () {
      return [
        {
          text: this.$t('topics.breadcrumbList'),
          disabled: false,
          name: 'TopicList'
        },
        {
          text: this.$t('topics.breadcrumb'),
          disabled: true
        }
      ]
    },
    isCacPng: {
      get () {
        return IS_CAC || IS_PNG
      }
    },
    external_id: {
      get () {
        return this.currentItem.external_id
      },
      set (value) {
        this.updateCurrentItem({ ...this.currentItem, external_id: value })
      }
    },
    name: {
      get () {
        return this.currentItem.name
      },
      set (value) {
        this.updateCurrentItem({ ...this.currentItem, name: value })
      }
    },
    description: {
      get () {
        return this.currentItem.description
      },
      set (value) {
        this.updateCurrentItem({ ...this.currentItem, description: value })
      }
    },
    color: {
      get () {
        return this.currentItem.color
      },
      set (value) {
        this.updateCurrentItem({ ...this.currentItem, color: value })
      }
    },
    aggressiveness: {
      get () {
        return this.currentItem.aggressiveness
      },
      set (value) {
        this.updateCurrentItem({ ...this.currentItem, aggressiveness: value })
      }
    },
    active: {
      get () {
        return this.currentItem.active
      },
      set (value) {
        this.updateCurrentItem({ ...this.currentItem, active: value })
      }
    }
  },
  methods: {
    ...mapActions('budgetConcepts', ['putFormItem', 'updateCurrentItem', 'createBudgetConcept', 'reloadItemList']),
    ...mapActions({ addNotification: 'addNotification' }),
    closeForm () {
      this.$refs.form.reset()
      this.updateCurrentItem({})
      this.$emit('close')
    },
    showAlertAction (text) {
      this.textAlert = text
      this.showAlert = true
    },
    async displayNotification (response) {
      if (response.status === 403) {
        await this.addNotification(createWarningNotification(this.$t('general.permissionsError')))
      } else if (response.status === 200) {
        await this.addNotification(createSuccessNotification(this.$t('dialogs.successUpdate')))
      } else {
        await this.addNotification(createErrorNotification(this.$t('dialogs.errorWhenSaving')))
      }
    },
    async saveForm () {
      await this.saveAndContinue()
      this.closeForm()
    },
    async saveAndContinue () {
      if (this.$refs.form.validate()) {
        if (this.currentItem.id === undefined) {
          await this.createBudgetConcept()
            .then(response => {
              this.displayNotification(response)
              this.reloadItemList()
            })
        } else {
          await this.putFormItem(this.currentItem)
            .then(response => {
              this.displayNotification(response)
            })
        }
      }
    }
  },
  updated () {
    this.$refs.form.resetValidation()
  },
  beforeDestroy () {
    this.$refs.form.reset()
    this.updateCurrentItem({})
  }
}
</script>

<style scoped>

</style>
