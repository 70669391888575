<template>
  <CrudTable
    v-bind="crudTableConfig"
    :storeModule="storeModule"
    :adaptableHeight="true"
    :showActionsPanel="false"
  >

    <template v-slot:form="{close}">
      <TopicForm showHeader @close="close"/>
    </template>

    <template v-slot:item.external_id="{ item }">
      <router-link :to="{ name: 'TopicDetail', params: { id: item.id }}">
        {{ item.external_id }}
      </router-link>
    </template>
  </CrudTable>
</template>

<script>
import TopicForm from '../forms/TopicForm'
import CrudTable from '@/lib/uncrudtable/components/CrudTable'
import { mapState } from 'vuex'

export default {
  name: 'TopicTable',
  components: { TopicForm, CrudTable },

  data () {
    return {
      storeModule: 'budgetConcepts'
    }
  },
  computed: {
    ...mapState({
      crudTableConfig: function (state) { return state[this.storeModule].crudTableConfig }
    })
  }
}
</script>

<style scoped>

</style>
